'use client'

import { useEffect } from 'react'

import { BRANCH_KEY } from 'config/constants'
import { initBranch, getBrowserFingerprintId, setIdentity, getBranchInstance } from 'lib/branch'
import useUserStore from 'store/user'

initBranch(BRANCH_KEY, {}, (err) => {
  if (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to load Branch.io', err)
  }
})

const useBranchLog = () => {
  const { user, isLoggedIn } = useUserStore((state) => state)

  const handleSetIdentity = (browserFingerPrint: string) => {
    if (isLoggedIn && user) {
      setIdentity(`${user.id}`)

      return
    }

    // fallback identity branch to browserFingerPrint
    setIdentity(browserFingerPrint)
  }

  useEffect(() => {
    if (getBranchInstance()) {
      getBrowserFingerprintId((e, browserFingerPrint) => {
        if (e) {
          return
        }

        handleSetIdentity(String(browserFingerPrint))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useBranchLog
