'use client'

import { PropsWithChildren } from 'react'

import useBrowserLog from './useBrowserLog'
import useBranchLog from './useBranchLog'

const BrowserLog = ({ children }: PropsWithChildren) => {
  useBrowserLog()
  useBranchLog()

  return children
}

export default BrowserLog
