import { parseCookie } from 'utils/cookie'
import type { NativeHeaders } from 'type/nativeHeaders'

export type WebviewSessionPropsType<NativeDataType = Record<string, unknown>> = {
  nativeHeaders: NativeHeaders | null
  initialNativeData?: NativeDataType
  isWebview: boolean
  isAuthenticated: boolean
  hasSessionCheck: boolean
}

export const initialWebviewSessionProps: WebviewSessionPropsType = {
  nativeHeaders: null,
  initialNativeData: {},
  isWebview: false,
  isAuthenticated: false,
  hasSessionCheck: false,
}

export const getWebviewSessionProps = (
  cookieString: string,
  searchParams: Record<string, unknown> = {},
) => {
  const cookies = parseCookie(cookieString)

  const nativeHeaders: NativeHeaders | null = cookies?.nativeHeaders
    ? JSON.parse(cookies.nativeHeaders)
    : null

  const initialNativeData = cookies?.initialNativeData
    ? JSON.parse(cookies.initialNativeData)
    : null

  const mergedInitialDataWithSearchParams: Record<string, unknown> = {
    ...(initialNativeData || {}),
    ...searchParams,
  }

  const normalizeNativeHeaders = nativeHeaders
    ? ({
        ...nativeHeaders,
        Authorization: nativeHeaders.Authorization || nativeHeaders.AUTHORIZATION,
      } as NativeHeaders)
    : null

  delete normalizeNativeHeaders?.AUTHORIZATION

  return {
    nativeHeaders: normalizeNativeHeaders,
    initialNativeData: mergedInitialDataWithSearchParams,
    isWebview: searchParams?.webview === 'true',
    isAuthenticated: !!normalizeNativeHeaders?.Authorization,
    hasSessionCheck: true,
  } as WebviewSessionPropsType
}

export const getWebviewSessionPropsFromWindowCtx = (
  nativeHeaders: NativeHeaders,
  searchParams: Record<string, unknown> = {},
) => {
  const normalizeNativeHeaders = nativeHeaders
    ? ({
        ...window.nativeHeaders,
        Authorization: window.nativeHeaders.Authorization || window.nativeHeaders.AUTHORIZATION,
      } as NativeHeaders)
    : null

  delete normalizeNativeHeaders?.AUTHORIZATION

  return {
    nativeHeaders: normalizeNativeHeaders,
    initialNativeData: {},
    isWebview: searchParams?.webview === 'true',
    isAuthenticated: !!normalizeNativeHeaders?.Authorization,
    hasSessionCheck: true,
  } as WebviewSessionPropsType
}

export const getWebviewSessionPropsDefault = (searchParams: Record<string, unknown> = {}) =>
  ({
    nativeHeaders: null,
    initialNativeData: {},
    isWebview: searchParams?.webview === 'true',
    isAuthenticated: false,
    hasSessionCheck: true,
  } as WebviewSessionPropsType)
