'use client'

import { AstroUIProvider } from '@astronautsid/wpe-astro-ui/provider'
import { createTheme } from '@astronautsid/wpe-astro-ui/theme'
import { text } from '@astronautsid/wpe-astro-ui/tokens/text'

import { nunitoSans } from 'app/_fonts'

const customTypography = {
  ...text,
  fontFamily: nunitoSans.style.fontFamily,
}

const theme = createTheme('light', {
  typography: customTypography,
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: nunitoSans.style.fontFamily,
      },
    },
  },
})

const ThemeRegistry = ({ children }: { children: React.ReactNode }) => (
  <AstroUIProvider theme={theme} disableInjection>
    {children}
  </AstroUIProvider>
)

export default ThemeRegistry
