/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/unbound-method */

'use client'

import React, { memo, useEffect, useRef } from 'react'
import NProgress from 'nprogress'
import { usePathname, useSearchParams } from 'next/navigation'

export type ProgressBarPropsType = {
  color?: string
  delay?: number
  height?: number | string
  showOnShallow?: boolean
  options?: Partial<NProgress.NProgressOptions>
  style?: string
}

interface ExtendedHTMLAnchorElementInterface extends HTMLAnchorElement {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __eventListenerAdded?: boolean
}

type PushStateInputType = [data: unknown, unused: string, url?: string | URL | null | undefined]

const ProgressBar = ({
  color = '#256ee5',
  height = '2px',
  options,
  showOnShallow = false,
  delay = 0,
  style,
}: ProgressBarPropsType) => {
  const styles = (
    <style>
      {style ||
        `
          #nprogress {
            pointer-events: none;
          }

          #nprogress .bar {
            background: ${color};

            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;

            width: 100%;
            height: ${height};
          }

          /* Fancy blur effect */
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
            opacity: 1.0;

            -webkit-transform: rotate(3deg) translate(0px, -4px);
                -ms-transform: rotate(3deg) translate(0px, -4px);
                    transform: rotate(3deg) translate(0px, -4px);
          }

          /* Remove these to get rid of the spinner */
          #nprogress .spinner {
            display: block;
            position: fixed;
            z-index: 1031;
            top: 15px;
            right: 15px;
          }

          #nprogress .spinner-icon {
            width: 18px;
            height: 18px;
            box-sizing: border-box;

            border: solid 2px transparent;
            border-top-color: ${color};
            border-left-color: ${color};
            border-radius: 50%;

            -webkit-animation: nprogress-spinner 400ms linear infinite;
                    animation: nprogress-spinner 400ms linear infinite;
          }

          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }

          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }

          @-webkit-keyframes nprogress-spinner {
            0%   { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }
          @keyframes nprogress-spinner {
            0%   { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
    </style>
  )

  NProgress.configure(options || {})

  const pathname = usePathname()
  const searchParams = useSearchParams()

  const mutationObserverRef = useRef<Nullable<MutationObserver>>(null)
  const callbackElementsRef = useRef<Nullable<ExtendedHTMLAnchorElementInterface[]>>(null)
  const timerRef = useRef<Nullable<NodeJS.Timeout>>(null)

  const validateAnchorClick = (event: MouseEvent, callback: () => void) => {
    const anchorElement = event.currentTarget as ExtendedHTMLAnchorElementInterface

    // skip anchors with target="_blank" | "_top" | "_parent" | "_self"
    if (
      anchorElement.target === '_blank' ||
      anchorElement.target === '_top' ||
      anchorElement.target === '_parent'
    )
      return

    // skip anchors with download attribute
    if (anchorElement.hasAttribute('download')) return

    // target url without hash removed
    const targetUrl = new URL(anchorElement.href)
    const currentUrl = new URL(window.location.href)

    // check if search params changed
    const hasSearchParams =
      targetUrl?.searchParams?.toString() !== currentUrl?.searchParams?.toString()
    const paramsChanged = hasSearchParams && targetUrl?.search !== currentUrl?.search
    const isSameUrl = targetUrl?.pathname === currentUrl?.pathname && !paramsChanged

    // detect ctrl/cmd option/alt shift click
    if (event.metaKey || event.ctrlKey || event.shiftKey || event.altKey) return

    if (showOnShallow && isSameUrl) return
    if (isSameUrl) return

    callback()
  }

  const startProgress = () => {
    timerRef.current = setTimeout(NProgress.start, delay)
  }

  const handleAnchorClick = (event: MouseEvent) => {
    validateAnchorClick(event, () => {
      startProgress()
    })
  }

  const removeListeners = () => {
    if (callbackElementsRef?.current?.length) {
      callbackElementsRef.current.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick)
      })
    }
  }

  const stopProgress = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    removeListeners()
    NProgress.done()
  }

  const handleMutation: MutationCallback = () => {
    const anchorElements = document.querySelectorAll('a')
    const validAnchorElements = Array.from(anchorElements).filter((anchor) => {
      if (anchor.href.startsWith('tel:+') || anchor.href.startsWith('mailto:')) return false
      return anchor.href && anchor.target !== '_blank'
    }) as ExtendedHTMLAnchorElementInterface[]

    callbackElementsRef.current = [...validAnchorElements]

    validAnchorElements.forEach((anchor) => {
      if (!anchor.__eventListenerAdded) {
        anchor.addEventListener('click', handleAnchorClick)
        // eslint-disable-next-line no-param-reassign
        anchor.__eventListenerAdded = true
      }
    })
  }

  useEffect(() => {
    NProgress.done()
  }, [pathname, searchParams])

  useEffect(() => {
    const mutationObserver = new MutationObserver(handleMutation)
    mutationObserver.observe(document, { childList: true, subtree: true })
    mutationObserverRef.current = mutationObserver

    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray: PushStateInputType) => {
        stopProgress()
        return target.apply(thisArg, argArray)
      },
    })

    window.addEventListener('beforeunload', removeListeners)
    return () => {
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect()
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      NProgress.done()
      removeListeners()
      window.removeEventListener('beforeunload', removeListeners)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return styles
}

export default memo(ProgressBar, () => true)
