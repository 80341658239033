'use client'

import { useState, useEffect } from 'react'

const Offline = () => {
  const [isOffline, setOffline] = useState<boolean>(false)

  useEffect(() => {
    const offlineHandler = () => setOffline(true)
    const onlineHandler = () => setOffline(false)

    window.addEventListener('offline', offlineHandler)
    window.addEventListener('online', onlineHandler)

    return () => {
      window.removeEventListener('offline', offlineHandler)
      window.removeEventListener('online', onlineHandler)
    }
  })

  if (!isOffline) return null

  return (
    <div>
      <p style={{ color: 'red', fontWeight: 'bold' }}>
        No internet connection detected, please check your connection
      </p>
    </div>
  )
}

export default Offline
