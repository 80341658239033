import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { initialWebviewSessionProps, type WebviewSessionPropsType } from 'utils/webview/session'
import { logger } from 'utils/store'

export type AuthSliceType = {
  auth: {
    isAuthenticated: boolean
    user: {
      firstName: string
      lastName: string
      phoneNumber: string
      active: boolean
      isFraud: boolean
    }
  }
  webviewSessionProps: WebviewSessionPropsType
  updateAuth: (payload: Partial<AuthSliceType['auth']>) => void
  setWebviewSessionProps: (payload: WebviewSessionPropsType) => void
}

export const authState = {
  auth: {
    isAuthenticated: false,
    user: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      active: false,
      isFraud: false,
    },
  },
  webviewSessionProps: initialWebviewSessionProps,
}

const SLICE_NAME = 'authStore'

const useAuthStore = create<AuthSliceType>()(
  logger(
    immer(
      persist(
        (set) => ({
          ...authState,
          updateAuth: (payload = {}) =>
            set((state) => ({
              auth: {
                ...state.auth,
                ...payload,
              },
            })),
          setWebviewSessionProps: (payload) => {
            set((state) => ({
              webviewSessionProps: {
                ...state.webviewSessionProps,
                ...payload,
              },
            }))
          },
        }),
        {
          name: SLICE_NAME,
          storage: createJSONStorage(() => sessionStorage),
          partialize: (state) =>
            Object.fromEntries(
              Object.entries(state).filter(([key]) => !['webviewSessionProps'].includes(key)),
            ),
        },
      ),
    ),
    SLICE_NAME,
  ),
)

export default useAuthStore
