'use client'

import { PropsWithChildren, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ENABLE_REACT_QUERY_DEV_TOOL } from 'config/constants'

import { queryDefaultOptions } from './config'

type ReactQueryProviderType = PropsWithChildren

const ReactQueryProvider = ({ children }: ReactQueryProviderType) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            ...queryDefaultOptions,
          },
        },
      }),
  )

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {ENABLE_REACT_QUERY_DEV_TOOL && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
